import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '@lib/serializers'
import cx from 'classnames'
import Photo from '@components/photo'
import CustomLink from '@components/link'

import ProductCard from '@blocks/shop/product-card'
import {
  ProductGallery,
  ProductThumbnail,
  ProductPrice,
  ProductOption,
} from '@blocks/product'
import ProductCardMinimal from '@blocks/shop/product-card-minimal'
const Squares = ({ data = {} }) => {
  const { squares, hideTitle, title, narrow } = data

  if (!squares.length) return null
  return (
    <>
      {!hideTitle && <h2 className="section-title">{title}</h2>}
      <section className="squares">
        <div
          className={cx('squares-items', {
            'square-items--narrow': narrow,
          })}
        >
          {squares.map((square, i) => (
            <div
              key={i}
              className={cx('square-item', {
                [`square-item--${square.theme}`]: square.theme,
                'square-item--emphasise': square.emphasise,
              })}
            >
              <div
                className={cx(
                  'square-item--wrapper',
                  square.itemAlignment || 'justify-center'
                )}
              >
                {square._type === 'squareContent' ? (
                  <div className="square-item--content">
                    <BlockContent
                      className="rc"
                      renderContainerOnSingleChild
                      blocks={square.content}
                      serializers={serializers}
                    />
                  </div>
                ) : null}
                {square._type === 'squareImage' ? (
                  <div className="square-item--photo-wrap">
                    <div className="square-item--photo">
                      {square.emphasise ? (
                        <Photo
                          photo={square.photo}
                          layout="fill"
                          objectFit="cover"
                        />
                      ) : (
                        <Photo
                          photo={square.photo}
                          layout="fill"
                          objectFit="contain"
                        />
                      )}
                    </div>
                  </div>
                ) : null}
                {square._type === 'squareProduct' ? (
                  <div className="square-item--product-wrap">
                    <div className="square-item--product">
                      <ProductCardMinimal product={square.product} />
                    </div>
                  </div>
                ) : null}

                {square.caption ? (
                  <div className="square-item--caption">
                    <span>{square.caption}</span>
                  </div>
                ) : null}

                {square.details ? (
                  <div className="square-item--details">
                    <BlockContent
                      className="rc"
                      renderContainerOnSingleChild
                      blocks={square.details}
                      serializers={serializers}
                    />
                  </div>
                ) : null}

                {square.link ? (
                  <CustomLink
                    link={{
                      ...square.link,
                      className: 'square-item--link',
                    }}
                  />
                ) : null}
                {/* <div class="card--cta">
                <div class="card--dot">
                  <span>Donate Now</span>
                </div>
              </div> */}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}

export default Squares
